body {
  margin: 0;
  font-family: 'Hahmlet', serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Hahmlet', serif;
}

.font-link{
  font-family: 'Titillium Web', sans-serif;
}

.login-page{
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background-color: #629b71;
}

.login-card{
  background-color: white;
  width: 40%;
  height: 50%;
  display: flex;
  justify-content: center;
  border: 1px solid gray;
  border-radius: 1em;
  box-shadow: 0 0 8px 5px #49494998;
}

.login-logo{
  width: 100%;
  display: flex;
  justify-content: flex-start;
  position: absolute;
  top: 0;
}

.home-layout{
  color: #9effb8;
}

.home-header-div{
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.home-header-div img{
  width: 25%;
}

.button-link{
  width:95%; 
  display:flex;
  justify-content:space-between;
  align-items:center;
  border:none; 
  background-color:transparent; 
  cursor:pointer;
  color:black;
}

.link{
  background-color: transparent;
  border:none; 
  border-radius: 5px;
  border-bottom:1px solid #e1e1e1; 
}

tr:hover{
  background-color: rgba(159, 217, 255, 0.363) !important;
}
tr:hover td{
  background-color: rgba(159, 217, 255, 0.363) !important;
}